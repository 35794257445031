import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-name-exists',
    templateUrl: './name-exists.component.html',
    styleUrls: ['./name-exists.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
    ],
})
export class NameExistsComponent {
    @Input({ required: true }) existingName: string;
    @Input({ required: true }) suggestedPrefix: string;
}